import React, { useContext, useEffect } from "react";
import {
  ModalLabel,
  ModalHelp,
  ModalHeader,
  ModalDropdownScreen,
  ModalDropdownSelectOption,
} from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { ReactComponent as TriggerSVG } from "@assets/trigger.svg";
import TurnaroundAttributeSelector from "../TurnaroundAttributeSelector";
import { IncidentModalStoreContext } from "@services/react";
import { ModalSpacer } from "../../Common/ModalSpacer";
import { FormattedMessage, useIntl } from "react-intl";
import { notificationTriggerLabels } from "@i18n/messages";
import { TriggerKeys, triggerKeys } from "@models/incidentConfig";

const IncidentAttributeScreen = () => {
  const {
    closeModal,
    setScreen,
    trigger,
    setTrigger,
    getInvalidFields,
    selectedAttributeKey,
    markScreenAsShown,
  } = useContext(IncidentModalStoreContext);
  const intl = useIntl();

  useEffect(() => () => markScreenAsShown(selectedAttributeKey), []);

  const triggerOptions: ModalDropdownSelectOption<TriggerKeys>[] =
    triggerKeys.map((v) => ({
      title: intl.formatMessage(notificationTriggerLabels[v]),
      id: v,
    }));
  const triggerTitle = intl.formatMessage(notificationTriggerLabels[trigger]);

  const invalidFields = getInvalidFields(selectedAttributeKey);

  return (
    <>
      <ModalHeader
        onBack={() => setScreen("main")}
        onClose={closeModal}
        icon={<TriggerSVG />}
        title={intl.formatMessage({
          defaultMessage: "Attribute settings",
          description: "Incident modal attr setting screen title",
        })}
      />
      <TurnaroundAttributeSelector
        sourceTitle={intl.formatMessage({
          defaultMessage: "attribute configuration",
          description: "incident modal attr selector title title",
        })}
      />
      <ModalLabel
        title={intl.formatMessage({
          defaultMessage: "Action trigger",
          description: "incident modal attr setting screen title",
        })}
      >
        <ModalDropdownScreen
          error={invalidFields.includes("parameterName")}
          placeholder={intl.formatMessage({
            defaultMessage: "Select the trigger...",
            description: "incident modal",
          })}
          title={intl.formatMessage({
            defaultMessage: "Action trigger",
            description: "incident modal",
          })}
          value={triggerTitle}
          options={triggerOptions}
          selectedIds={[trigger]}
          onSelect={([v]) => setTrigger(v)}
        />
      </ModalLabel>
      <ModalSpacer minHeight={"8px"} />
      <ModalHelp>
        <FormattedMessage
          defaultMessage="Attribute settings define the actions"
          description="Incident modal help"
        />
      </ModalHelp>
    </>
  );
};

export default observer(IncidentAttributeScreen);
