import { makeAutoObservable, runInAction } from "mobx";
import { DutyPhone, NewDutyPhone } from "@models/dutyPhone";
import { DutyPhonesApi } from "@services/types";
import { groupBy } from "lodash";
import { defineMessage } from "react-intl";
import { IDutyPhonesStore } from "./index.types";
import { IHomeStore } from "../HomeStore/index.types";

export type { IDutyPhonesStore } from "./index.types";

export default class DutyPhonesStore implements IDutyPhonesStore {
  dutyPhones: DutyPhone[] = [];
  dutyPhonesModalData: DutyPhone | NewDutyPhone | null = null;
  ready = false;
  highlightItemId: string | null = null;

  constructor(
    private home: IHomeStore,
    private api: DutyPhonesApi,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get groupedDutyPhones() {
    return groupBy(this.dutyPhones, "group");
  }

  async initDutyPhones() {
    try {
      const dutyPhones = await this.api.getDutyPhones();
      runInAction(() => {
        this.dutyPhones = dutyPhones;
      });
    } catch (e) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: something went wrong",
          description: "Error message when duty phones could not be loaded",
        }),
        type: "error",
      });
      throw e;
    } finally {
      runInAction(() => {
        this.ready = true;
      });
    }
  }

  /**
   * Opens modal for editing existing duty phone
   */
  editDutyPhone(data: DutyPhonesStore["dutyPhonesModalData"] = null) {
    this.dutyPhonesModalData = data;
  }

  /**
   * Opens modal for new duty phone
   */
  createDutyPhone(group: string) {
    this.editDutyPhone({
      group,
      active: true,
      phone: "",
      description: "",
    });
  }

  async saveDutyPhone(data: NewDutyPhone | DutyPhone) {
    const dutyPhone = await ("id" in data
      ? this._handlePutDutyPhone(data)
      : this._handleCreateDutyPhone(data));

    dutyPhone && this.setHighlightItem(dutyPhone.id);
  }

  async deleteDutyPhones(ids: string[]) {
    try {
      await this.api.deleteDutyPhones(ids);

      runInAction(() => {
        this.dutyPhones = this.dutyPhones.filter((v) => !ids.includes(v.id));
      });

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The duty phone has been added",
          description: "Success message when duty phones have been deleted",
        }),
        type: "info",
      });
    } catch (e) {
      // Can throw an error
      this.home.handlePhoneError(e, false);

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when duty phones have not been deleted",
        }),
        type: "error",
      });

      throw e;
    }
  }

  async toggleDutyPhonesActive(ids: string[], active: boolean) {
    const filteredPhones = this.dutyPhones
      .filter(({ id }) => ids.includes(id))
      .map((dutyPhone) => ({ ...dutyPhone, active }));

    try {
      const dutyPhones = await this.api.toggleDutyPhonesActive(filteredPhones);
      dutyPhones.forEach((dutyPhone) => this._updateDutyPhone(dutyPhone));

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The changes have been applied",
          description: "Success message when duty phones have been toggled",
        }),
        type: "info",
      });
    } catch (e) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when duty phones have not been toggled",
        }),
        type: "error",
      });
      throw e;
    }
  }

  setHighlightItem(id: DutyPhonesStore["highlightItemId"]) {
    this.highlightItemId = id;
  }

  private async _handleCreateDutyPhone(data: NewDutyPhone) {
    try {
      const dutyPhone = await this.api.createDutyPhone(data);

      runInAction(() => this.dutyPhones.push(dutyPhone));

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The duty phone has been added",
          description: "Success message when duty phones have been added",
        }),
        type: "info",
      });

      return dutyPhone;
    } catch (e) {
      // Can throw an error
      this.home.handlePhoneError(e);

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when duty phones have not been added",
        }),
        type: "error",
      });
      throw e;
    }
  }

  private async _handlePutDutyPhone(data: DutyPhone) {
    try {
      const dutyPhone = await this.api.putDutyPhone(data);

      this._updateDutyPhone(dutyPhone);

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The changes have been applied",
          description: "Success message when duty phones have been updated",
        }),
        type: "info",
      });

      return dutyPhone;
    } catch (e) {
      // Can throw an error
      this.home.handlePhoneError(e);

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when duty phones have not been updated",
        }),
        type: "error",
      });
      throw e;
    }
  }

  private _updateDutyPhone(data: DutyPhone) {
    const idx = this.dutyPhones.findIndex((n) => n.id === data.id);
    if (idx < 0) {
      this.dutyPhones.push(data);
      return;
    }

    this.dutyPhones[idx] = data;
  }
}
